<template>
    <div>
        <form class="needs-validation h-80" @submit.prevent="showConfirmModal">
            <div class="flex flex-wrap overflow-hidden h-full">
                <div class="w-full xl:my-3 xl:px-3 xl:w-1/2">
                    <!-- Column Content -->
                    <div class="mb-4">
                        <label
                            class="block text-gray-700 text-sm font-normal mb-2"
                            for="username"
                        >
                            Select Agent
                        </label>
                     
                        <multiselect
                            v-model="form.agent"
                            :options="agents"
                            label="entity_name"
                            placeholder="Select an agent"
                            :custom-label="labelWithID"
                        >
                            <template
                                slot="singleLabel"
                                slot-scope="{ option }"
                            >
                                <strong>{{ option.entity_name }}</strong>
                                <span style="color: red"
                                    >&nbsp;{{ option.phone }}</span
                                >
                            </template>
                        </multiselect>
                    </div>
                </div>

                <div class="w-full xl:my-3 xl:px-3 xl:w-1/2">
                    <!-- Column Content -->
                    <div class="mb-4">
                        <label
                            class="block text-gray-700 text-sm font-normal mb-2"
                            for="username"
                        >
                            Amount
                        </label>
                        <input
                            class="
                                shadow
                                appearance-none
                                border
                                rounded
                                w-full
                                py-3
                                px-3
                                text-gray-700
                                leading-tight
                                focus:outline-none focus:shadow-outline
                            "
                            name="name"
                            v-model="form.amount"
                            type="number"
                            :class="{
                                'is-invalid': form.errors.has('amount'),
                            }"
                            required
                            placeholder="Enter amount"
                        />
                    </div>
                </div>

                <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/2">
                    <!-- Column Content -->
                    <button
                        class="
                            px-4
                            py-2
                            rounded
                            text-white
                            inline-block
                            shadow-lg
                            bg-blue-500
                            hover:bg-blue-600
                            focus:bg-blue-700
                        "
                        @click="showConfirmModal()"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </form>

        <div v-if="form.agent">
            <confimation
                v-show="isConfirmModalVisible"
                modalHeadline="Please confirm?"
                :isLoading="isLoading"
                :confirmMessage="`Are you sure you want to make a deposit of GMD${form.amount} for ${form.agent.entity_name}`"
                @confirmEvent="confirmRequest"
                @close="closeConfirmModal"
            />
        </div>
    </div>
</template>

<script>
import Form from 'vform';
import axios from 'axios';
import moment from "moment";
import Confimation from '../../../components/Confimation.vue';
import JSPM from "../../../../public/JSPrintManager";

export default {
    components: { Confimation },
    props: ['user'],
    data: () => ({
        isLoading: false,
        isConfirmModalVisible: false,
        accounts: [],
        agents: [],
        userHasConfirmed: false,
        print2default: true,
        form: new Form({
            name: '',
            account: null,
            agent: null,
            amount: '',
        }),
    }),

    mounted() {
        this.loadAgents();
        this.onInit();
    },

    methods: {
        labelWithID({ entity_name, phone }) {
            return `${entity_name} — (${phone})`;
        },

        async loadAgents() {
            try {
                const { data } = await axios.get(`/get/agents`);

                this.agents = data.data;
                return true;
            } catch (e) {
                console.log('Error');
                return false;
            }
        },

        showConfirmModal() {
            this.isConfirmModalVisible = true;
        },
        closeConfirmModal() {
            this.isConfirmModalVisible = false;
        },

        confirmRequest(req) {
            console.log('User has confirmed', req);
            this.userHasConfirmed = req;

            if (this.userHasConfirmed == 'yes') {
                this.onSubmit();
            }
        },

        async onSubmit() {
            // Submit the form
            this.isLoading = true;

            try {
                const { data } = await this.form.post('/deposit/agent/cx');

                console.log("Data", data); 

                this.print(data.data)

                this.isLoading = false;
                this.$router.push({ path: '/dashboard' });

                return true;
            } catch (e) {
                this.isLoading = false;

                console.log('Error', e);
                return false;
            }
        },

        print: function (receipt) {
            console.log("receipt", receipt);

            if (this.selected_printer === "" && !this.print2default) {
                alert("You must select a printer");
                return;
            }

            var cpj = new JSPM.ClientPrintJob();

            if (this.print2default) {
                cpj.clientPrinter = new JSPM.DefaultPrinter();
            } else {
                cpj.clientPrinter = new JSPM.InstalledPrinter(this.selected_printer);
            }

            var day = new Date(receipt.created_at);
            var dateCreated = moment().utc(day).format("Do MMM, YYYY, h:mm:ss a");

            //Set content to print...
            //Set content to print...
            //Create ESP/POS commands for sample label
            var esc = "\x1B"; //ESC byte in hex notation
            var newLine = "\x0A"; //LF byte in hex notation
            var fullCut = esc + "m";

            var cmds = esc + "@"; //Initializes the printer (ESC @)
            cmds += esc + "!" + "\x38"; //Emphasized + Double-height + Double-width mode selected (ESC ! (8 + 16 + 32)) 56 dec => 38 hex
            cmds += "CHAPMAN XPRESS"; //text to print
            cmds += newLine;
            cmds += esc + "!" + "\x00"; //Character font A selected (ESC ! 0)

            cmds += newLine + newLine;

            cmds += "Agent Name: " + this.form.agent.entity_name;
            cmds += newLine;
            cmds += `Amount .: GMD ${receipt.amount / 100}`;
            cmds += newLine + newLine;


           
            cmds += `Deposit Date .:      ${dateCreated}`;

            cmds += newLine + newLine;
            cmds += esc + "!" + "\x18"; //Emphasized + Double-height mode selected (ESC ! (16 + 8)) 24 dec => 18 hex
            cmds += `Operator: ${this.user.data.name}`;
            cmds += newLine;
            cmds += esc + "!" + "\x00"; //Character font A selected (ESC ! 0)

            cmds += "Thank you";
            cmds += newLine;

            // cmds += esc + '!' + '\x38';

            cmds += newLine;
            cmds += `Phone: +220 438 3223 - 307 4455 - ${this.user.data.staff.branch_contact_phone}`;
            cmds += newLine + newLine;
            cmds += newLine + newLine;

            cmds += fullCut;

            cpj.printerCommands = cmds;
            //Send print job to printer!
            cpj.sendToClient();

            // this.receipt = null;
        },

        onInit: function () {
            console.log("IN INIT", JSPM);
            var _this = this;
            JSPM.JSPrintManager.auto_reconnect = true;
            JSPM.JSPrintManager.start();
            JSPM.JSPrintManager.WS.onStatusChanged = function () {
                _this.getPrinters().then((p) => {
                _this.printers = p;
                _this.$nextTick();
                });
        };
    },

    getPrinters: function () {
      return new Promise((ok, err) => {
        let printers = [];
        if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
          JSPM.JSPrintManager.getPrinters()
            .then(function (myPrinters) {
              printers = myPrinters;
              console.log("printers", printers);
              ok(printers);
            })
            .catch((e) => err(e));
        } else {
          console.warn("JSPM WS not open");
          ok(printers);
        }
      });
    },
    },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
